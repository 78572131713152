import { Link, useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import HeadShot from './HeadShot'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => {
  const [isOpen, setOpen] = useState(true)
  const data = useStaticQuery(
    graphql`
      query {
        sanityAbout {
          name
        }
      }
    `
  )

  return (
    <header className=''>
      <div className='relative z-20 flex justify-between items-center'>
        <div className='flex md:block lg:flex items-center max-w-full'>
          <HeadShot />
          <div>
            {/* name start */}
            <Link
              to='/'
              className='block text-black no-underline font-bold text-xl lg:text-4xl font-extrabold leading-none lg:leading-tight'
            >
              {data.sanityAbout.name}
            </Link>
            {/* name end */}
            {/* nav start */}
            <div className='hidden md:flex mt-3 lg:mt-4 uppercase tracking-wide text-xs spaced-x-6'>
              <Link
                to='/blog/1'
                className='tracking-wider text-gray-500 font-medium no-underline hover:text-black mr-5'
                activeStyle={{ color: 'black' }}
              >
                Blog
              </Link>
              <Link
                to='/article'
                className='tracking-wider text-gray-500 font-medium no-underline hover:text-black mr-5'
                activeStyle={{ color: 'black' }}
              >
                Yayınlar
              </Link>
              <Link
                to='/about'
                className=' tracking-wider text-gray-500 font-medium no-underline hover:text-black mr-5'
                activeStyle={{ color: 'black' }}
              >
                Hakkımda
              </Link>
            </div>
            {/* nav end */}
          </div>
        </div>
        <div className='block md:hidden'>
          <button className='block' onClick={() => setOpen(!isOpen)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              className='text-black h-6 w-6 fill-current'
            >
              {!isOpen
                ? <path d='M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z' />
                : <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
              }

            </svg>
          </button>
        </div>
      </div>

      <div
        className={isOpen ? 'hidden' : 'block'}
      >
        <div className='pt-4'>
          <Link
            to='/blog/1'
            className='block  tracking-wider text-gray-500 font-medium no-underline hover:text-black mr-5'
            activeStyle={{ color: 'black' }}
          >
            Blog
          </Link>
          <Link
            to='/article'
            className='block mt-2 tracking-wider text-gray-500 font-medium no-underline hover:text-black mr-5'
            activeStyle={{ color: 'black' }}
          >
            Yayınlar
          </Link>
          <Link
            to='/about'
            className='block mt-2 tracking-wider text-gray-500 font-medium no-underline hover:text-black mr-5'
            activeStyle={{ color: 'black' }}
          >
            About
          </Link>
        </div>
      </div>
    </header>
  )
}

export default Header
