import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const HeadShot = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityAbout {
        image {
          alt
          asset {
            id
            fluid(maxWidth: 100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div className='mb-0 md:mb-4 lg:mb-0 flex flex-no-shrink pr-4 md:pr-6 lg:pr-12'>
      <Link to='/' className='class="flex items-center no-underline'>
        <Img
          className='h-10 w-10 md:h-12 md:w-12 lg:h-20 lg:w-20 rounded-full'
          fluid={data.sanityAbout.image.asset.fluid}
          alt={data.sanityAbout.image.alt}
        />
      </Link>
    </div>
  )
}

export default HeadShot
