import React from 'react'
import Header from './header'
import '../styles/layout.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <div className='font-sans text-black leading-tight antialiased'>
    <div className='py-8 lg:py-16 px-6 md:px-16 lg:px-24'>
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <div className=''>{children}</div>
    </div>
  </div>
)

export default Layout
